.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000bf;
  overflow: hidden;
  overflow-y: auto;
  transition: opacity .3s;
  z-index: 999;
  backdrop-filter: blur(2px);
}

.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.modal-content {
  position: relative;
  margin: 20px;
  width: 75%;
  min-width: 240px;
  max-width: 600px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: #0000004d 0px 19px 38px, #00000038 0px 15px 12px;
  padding: 40px 24px;
  transform: translateY(-50px);
  transition: opacity .3s, transform .3s;
}

.modal-close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  padding: 0;
  background-color: transparent;
  transition: transform .1s;
}

.modal-close-button:active {
  transform: scale(0.95);
}

.modal-close-button svg {
  width: 100%;
  height: 100%;
  fill: #B72025;
  transition: fill .3s;
}

.modal-close-button:hover svg {
  fill: #721518;
}

.modal--entering,
.modal--entered {
  opacity: 1;
}

.modal--entering .modal-content,
.modal--entered .modal-content {
  transform: translateY(0);
}

.modal--exiting,
.modal--exited {
  opacity: 0;
}

.modal--exiting .modal-content,
.modal--exited .modal-content {
  transform: translateY(-50px);
}

.modal-children-congrats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.modal-children-congrats h2 {
 margin-bottom: 8px;
}
