.header {
  height: 80px;
  padding: 0 32px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #B72025;
  z-index: 22;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 2px 4px #0000000d;
}

@media screen and (max-height: 800px) {
  .header {
    height: 60px;
  }

  .nav-logo {
    width: 70px;
  }
}

@media screen and (max-height: 620px) {
  .header {
    height: 40px;
  }

  .nav-logo {
    width: 60px;
  }
}

.header > img {
  padding: 10px 0;
}

.mobile-menu-button {
  display: none;
}

.menu-container {
  position: absolute;
  right: 32px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.language-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.language-container img {
  position: absolute;
  left: 5px;
}

.language-container button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  color: #343232;
  padding: 4px 28px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.24px;
  background-color: #fff;
  border-radius: 3px;
  transition: box-shadow .2s ease-in-out, transform .2s ease-in-out;
}

.language-container button:hover {
  box-shadow: 0px 2px 8px 0px #34323226;
}

.button-language--active {
  border-color: #5D5B5B;
  box-shadow: 0px 2px 10px 0px #34323233;
}

.button-language::after {
  position: absolute;
  right: 10px;
  content: url('../../images/chevron-down.svg');
  transform: translateY(-3px);
}

.button-language--active::after {
  transform: translateY(3px) rotate(180deg);
}

.language-container ul {
  position: absolute;
  display: block;
  background-color: #fff;
  border: 2px solid #34323226;
  border-radius: 3px;
  box-shadow: 0px 2px 8px 0px #34323226;
  min-width: 100px;
  max-height: 180px;
  overflow: auto;
  bottom: -185px;
  z-index: 18;
}

.options-none {
  opacity: 0;
  height: 0;
}

.options-hide {
  height: 0;
  visibility: hidden;
}

.options-showed {
  animation: fadeInlanguage .2s linear;
}

.language-container li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 5px 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-radius: 4px;
  transition: background-color .2s ease-in-out, color .2s ease-in-out, border-color .2s ease-in-out;
}

.language-container li:hover {
  background-color: #faf5f5;
  color: #B72025;
  border-color: #B72025;
  box-sizing: border-box;
}

@media (max-width: 800px) {
  .header:not(.header-passphrase) {
    justify-content: space-between;
    padding: 0 20px;
  }

  .header-template {
    justify-content: center ;
  }

  .menu-container {
    right: 20px;
  }

  .mobile-menu-button {
    display: block;
    width: 28px;
    height: 20px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  
  .mobile-menu-button span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #fff;
    border-radius: 4px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  
  .mobile-menu-button span:nth-child(1) {
    top: 0px;
  }
  
  .mobile-menu-button span:nth-child(2), .mobile-menu-button span:nth-child(3) {
    top: 8px;
  }
  
  .mobile-menu-button span:nth-child(4) {
    top: 16px;
  }
  
  .mobile-menu-button.open span:nth-child(1) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
  
  .mobile-menu-button.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .mobile-menu-button.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  .mobile-menu-button.open span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
  }
}

.header-template .mobile-menu-button {
  display: none;
}

@keyframes fadeInlanguage {
  0% {
    height: 0;
    opacity: 0;
    bottom: -5px;
  }
  100% {
    height: 180px;
    opacity: 1
  }
}
