footer {
  display: flex;
  gap: 20px;
  height: 80px;
  padding: 0px 50px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #D6D6D6;
  z-index: 17;
}

footer a {
  text-decoration: none;
  color: #343232;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
}

@media screen and (max-height: 800px) {
  footer {
    height: 60px;
  }

  .footer-logo {
    width: 70px;
  }
}

@media screen and (max-height: 620px) {
  footer {
    display: none;
  }
}
