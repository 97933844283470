@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Inter', sans-serif;
  color: #190606;
  background: #F5F5F5;
}

body,
h1,
ul,
section,
menu,
switch {
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  min-width: 320px;
}

::-webkit-scrollbar-track
{
	background-color: #fff;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: #fff;
}

::-webkit-scrollbar-thumb
{
	border-radius: 4px;
	background-color: #E6E6E6;
}

body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 5px #a4a4a4;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
	width: 12px;
  height: 12px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 6px;
	background-color: #a4a4a4;
}

.tooltip-help {
  cursor: help !important;
}

::selection {
  color: #f0f0f0;
  background-color: #9D1B1F;
}

.page {
  height: 100vh;
  background: #F5F5F5;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  min-width: 200px;
}

.main {
  flex: 1;
  display: flex;
  height: calc(100vh - 300px);
}

.welcome-page {
  padding: 32px 50px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}

.welcome-page > div {
  position: relative;
  margin-bottom: 1.5rem;
  max-height: 25rem;
  min-height: 5rem;
  mask-image:
    linear-gradient(to top, transparent 0%, black 1%, black 99%, transparent 100%);
}

.welcome-page > div > img {
  position: relative;
  height: 100%;
  border-radius: 5px;
  filter: blur(0.5px);
  mask-image:
    linear-gradient(to left, transparent 0%, black 1%, black 99%, transparent 100%)
}

.welcome-page h2 {
  display: block;
  font-size: 1.2rem;
}

.welcome-page h2:first-of-type {
  text-align: center;
  margin-bottom: 1.5rem;
}

.welcome-page b {
  color: #9D1B1F
}

footer {
  bottom: 0;
}

@media screen and (max-height: 800px) {
  .main {
    height: calc(100vh - 220px);
  }
}

@media screen and (max-height: 620px) {
  .main {
    height: calc(100vh - 60px);
  }
}

@media screen and (max-width: 800px) {
  .main {
    height: 100%;
    overflow-y: scroll;
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    top: 100px;
  }
  75% {
    opacity: 0.5;
    top: 0px;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
