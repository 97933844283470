.vp-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: 150px;
  max-height: calc(100vh - 440px);
  overflow: hidden;
  font-size: clamp(1rem, 2vw, 1.2rem);
  color: #fff;
}

@media screen and (max-height: 840px) {
  .vp-container {
    max-height: calc(100vh - 410px);
  }
}

@media screen and (max-height: 800px) {
  .vp-container {
    max-height: calc(100vh - 370px);
  }
}

@media screen and (max-height: 620px) {
  .vp-container {
    max-height: calc(100vh - 100px);
  }
}

.thumbnail {
  position: absolute;
  object-fit: cover;
  width: 100%;
  z-index: 14;
}

.vp-container video,
.thumbnail {
  cursor: url('../../images/icons/Play\ button.svg') 26 26, auto;
  outline: none;
  animation: appear 0.3s linear;
}

.playback-paused {
  cursor: url('../../images/icons/Pause button.svg') 26 26, auto !important;
}

.vp-controls {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0;
  height: clamp(4rem, 15vw, 4rem);
  padding-bottom: 0.7rem;
  background: #B72025;
  transition: opacity 300ms ease-out;
  z-index: 15;
}
.vp-controls.hide {
  opacity: 0;
  pointer-events: none;
}
.vp-controls__header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: clamp(3rem, 100%, 5rem);
  margin-top: auto;
}
.vp-controls__body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  padding: 0 2rem 0 1rem;
  gap: 0.5rem;
}

.vp-controls__body > div {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.vp-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 3rem;
  max-width: 4rem;
  height: 100%;
  font-size: clamp(0.5rem, 1vw, 0.8rem);
  color: #fff;
  background-color: transparent;
}

.vp-controls__play .vp-btn {
  justify-content: flex-start;
  transform: translate(-3rem);
}
.vp-btn svg {
  width: clamp(1rem, 2vw, 1.5rem);
  height: 100%;
  transition: transform 200ms;
}
.vp-btn::before {
  content: attr(data-label);
  position: absolute;
  display: none;
  bottom: 130%;
  width: max-content;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  background-color: #000000cc;
  font-weight: 600;
  font-size: 0.8rem;
  pointer-events: none;
  opacity: 0;
  transition: opacity 200ms ease-out;
}
.vp-btn.label::before {
  display: block;
}
.vp-btn:not(:disabled):hover svg {
  transform: scale(1.3);
}
.vp-btn:hover::before {
  opacity: 1;
}

.vp-btn:disabled {
  cursor: not-allowed;
}

.vp-btn:disabled svg {
  opacity: 0.9;
  transform: translateY(-1px);
}

.vp-progress {
  position: relative;
  width: 100%;
  height: 100%;
}
.vp-progress__range {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.vp-progress__range--background,
.vp-progress__range--buffer,
.vp-progress__range--current,
.vp-progress__range--seek {
  position: absolute;
  width: 100%;
  height: 10%;
  border-radius: 50px;
}
.vp-progress__range--background {
  background-color: #858585;
}
.vp-progress__range--buffer {
  background-color: #6b0400;
  transition: width 200ms ease-out;
}
.vp-progress__range--current {
  position: relative;
  display: flex;
  align-items: center;
  height: 10%;
  background-color: #fff;
}
.vp-progress__range--current__thumb {
  position: absolute;
  right: 0;
  width: clamp(1rem, 1.5vw, 1rem);
  height: clamp(1rem, 1.5vw, 1rem);
  border-radius: 50px;
  background-color: #fff;
  transform: translateX(50%) scale(0);
  transition: transform 200ms ease-out;
}
.vp-progress__range--seek {
  height: 100%;
  cursor: not-allowed;
  opacity: 0;
}
.vp-progress__range--seek--active {
  cursor: pointer;
}
.vp-progress__range:hover .vp-progress__range--current__thumb {
  transform: translateX(50%) scale(1);
}
.vp-progress__tooltip {
  position: absolute;
  bottom: clamp(3rem, 5vw, 2rem);
  padding: 0.5rem 0.75rem;
  background-color: #000000cc;
  border-radius: 5px;
  font-weight: 500;
  font-size: 1rem;
  pointer-events: none;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 300ms ease-out;
}

.vp-progress:hover .vp-progress__tooltip--active {
  opacity: 1;
}

.vp-time {
  width: clamp(4rem, 20%, 5rem);
  text-align: center;
  font-size: clamp(0.8rem, 2vw, 0.8rem);
}

.vp-volume {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.vp-volume button {
  width: max-content;
  transition: transform 200ms ease-out;
}

.vp-volume:hover .vp-volume__range {
  transform: scaleX(1);
}
.vp-volume__range {
  position: absolute;
  display: flex;
  align-items: center;
  left: 45px;
  width: clamp(6rem, 8vw, 5rem);
  height: clamp(0.2rem, 1vw, 0.2rem);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 200ms ease-out;
}

.vp-volume__range--background,
.vp-volume__range--current,
.vp-volume__range--seek {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50px;
}

.vp-volume__range--background {
  background-color: #858585;
}
.vp-volume__range--current {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.vp-volume__range--current__thumb {
  position: absolute;
  right: 0;
  width: clamp(0.7rem, 1.5vw, 0.5rem);
  height: clamp(0.7rem, 1.5vw, 0.5rem);
  border-radius: 50px;
  background-color: #fff;
  transform: translateX(50%);
  transition: transform 200ms ease-out;
}
.vp-volume__range--seek {
  cursor: pointer;
  opacity: 0;
}
.vp-volume__range:hover .vp-volume__range--current__thumb {
  transform: translateX(50%) scale(1);
}

.vp-dropdown {
  position: absolute;
  bottom: 100%;
  right: 0;
  width: clamp(15rem, 15vw, 20rem);
  overflow: hidden;
  transition: opacity 200ms ease-out, height 300ms ease-out;
  background-color: #000000cc;
}
.vp-dropdown__menu {
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: transform 300ms ease;
}
.vp-dropdown__list {
  overflow: auto;
}
.vp-dropdown__label,
.vp-dropdown__item {
  display: flex;
  align-items: center;
  padding: clamp(0.5rem, 0.5vw, 0.5rem) clamp(0.5rem, 1vw, 2rem);
  font-size: 0.8rem;
  gap: 1rem;
  cursor: pointer;
  transition: background-color 200ms;
}

.vp-dropdown__label svg {
  width: 1.5rem;
  height: 1.5rem;
}
.vp-dropdown__label:hover,
.vp-dropdown__item:hover {
  background-color: #333333cc;
}
.vp-dropdown__label {
  font-weight: 600;
}
.vp-dropdown__item {
  justify-content: space-between;
}
.vp-dropdown__item.active::after {
  content: '';
  display: flex;
  width: 1rem;
  height: 1rem;
  border-radius: 50px;
  background-color: #fff;
}

.vp-dropdown-enter {
  opacity: 0;
}
.vp-dropdown-enter-active {
  opacity: 1;
}
.vp-dropdown-exit-active {
  opacity: 0;
}
.vp-menu-index-enter {
  transform: translateX(-110%);
}
.vp-menu-index-enter-active {
  transform: translateX(0%);
}
.vp-menu-index-exit-active {
  transform: translateX(-110%);
}
.vp-menu-main-enter {
  transform: translateX(110%);
}
.vp-menu-main-enter-active {
  transform: translateX(0%);
}
.vp-menu-main-exit-active {
  transform: translateX(110%);
}

.vp-controls__settings {
  justify-content: flex-end !important;
}

@media screen and (max-width: 860px) {
  .vp-controls__volume {
    max-width: 50px !important;
  }
  .vp-volume__range{
    width: clamp(5rem, 6vw, 5rem);
  }

  .vp-controls__play .vp-btn {
    transform: translate(-1rem);
  }
  .vp-progress__tooltip {
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
  }
}

@media screen and (max-height: 620px), screen and (max-width: 860px) {
  .vp-controls__play {
    justify-content: flex-end !important;
    margin-left: 30px;
  }
}

@media screen and (max-height: 620px), screen and (max-width: 800px) {
  .vp-volume:hover .vp-volume__range {
    display: none;
  }

  .vp-controls__volume,
  .vp-btn {
    width: 30px !important;
  }

  .vp-controls__play {
    justify-content: flex-end !important;
    margin-left: 30px;
  }

  .vp-controls__play .vp-btn {
    transform: translate(0);
  }

  .vp-dropdown {
    max-height: max-content !important;
  }

  .vp-dropdown__label,
  .vp-dropdown__item {
    padding: clamp(0.2em, 0.5vw, 0.2rem) clamp(0.5rem, 0.5vw, 0.5rem);
    font-size: 0.7rem;
  }

  .vp-btn {
    min-width: 0.1rem;
    margin: 0;
    svg {
      width: clamp(0.8rem, 1.5vw, 1rem);
    }
  }

  .vp-time {
    width: clamp(4rem, 20%, 3rem);
    font-size: clamp(0.7rem, 1vw, 0.7rem);
  }

  .vp-btn::before,
  .vp-progress__tooltip {
    padding: 0.3rem 0.6rem;
    font-weight: 500;
    font-size: 0.6rem;
  }

  .vp-progress__tooltip {
    bottom: clamp(2.5rem, 4vw, 2rem);
  }

  .vp-dropdown__label svg {
    width: 1.2rem;
    height: 1.2rem;
  }
  .vp-btn:disabled::before {
    transform: translateX(-15%);
  }
}

@media screen and (max-width: 600px) {
  .vp-controls {
    height: clamp(2rem, 10vw, 4rem);
    padding-bottom: 0.6rem;
  }

  .vp-time {
    width: clamp(4rem, 15%, 3rem);
    font-size: clamp(0.6rem, 1vw, 0.6rem);
  }
  .vp-controls__play {
    margin-left: 0;
  }

  .playback-paused,
  .vp-container video,
  .thumbnail {
    cursor: default !important;
  }
}

@media screen and (max-width: 460px) {
  .vp-time {
    width: clamp(4rem, 15%, 3rem);
    font-size: clamp(0.5rem, 1vw, 0.6rem);
  }
  .vp-volume button {
    display: none;
  }
}

video::cue {
  background-color: #000000a2;
  font-size: clamp(1.1rem, 1vw, 1.5rem);
  font-weight: 400;
  text-shadow: -1px -1px 0 #0000008f,
                1px -1px 0 #0000008f,
               -1px  1px 0 #0000008f,
                1px  1px 0 #0000008f;
}
