article {
  width: 100%;
  overflow-y: scroll;
}

.video-hide {
  visibility: hidden;
}

.video-player-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  height: 100%;
  width: 100%;
  min-height: 100px;
  padding: 24px 32px 24px 32px;
}

.video-player-title {
  width: 100%;
}

.video-player-container h3 {
  display: block;
  width: 100%;
  color: #343232;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.video-player-container video {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 10;
}

@media screen and (max-height: 620px) {
  .video-player-container {
    padding: 10px 32px 20px 32px;
    gap: 10px;
  }
}

.video-player-navigate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 12px;
  z-index: 16;
}

.video-player-navigate button {
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.video-player-navigate button .chevron-left,
.video-player-navigate button .chevron-right {
  position: absolute;
  width: max-content;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  background-color: #343232;
  font-weight: 600;
  font-size: 0.8rem;
  color: #fff;
  text-align: left;
  pointer-events: none;
  transition: opacity 200ms ease-out;
  opacity: 0;
}

.video-player-navigate button:not(:disabled):hover .chevron-left,
.video-player-navigate button:not(:disabled):hover .chevron-right {
  opacity: 1;
}

.video-player-navigate .chevron-left {
  left: -25px;
  top: -2.5rem;
}

.video-player-navigate .chevron-right {
  right: -25px;
  top: -2.5rem;
}

.video-player-navigate button img {
  padding: 3px 6px;
}

.video-player-navigate button:disabled {
  opacity: 0.3;
  cursor: default;
}
