.vp-error {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 2rem;
  font-size: 1.5em;
  font-weight: 600;
  color: #cd131c;
  background-color: #000000cc;
  z-index: 100;
}
.vp-error svg {
  width: 5rem;
  height: 5rem;
  cursor: pointer;
}
