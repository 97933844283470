.passhrase-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 32px 20px 40px;
  background: #FBFBFB;
}

.passhrase-container-video {
  justify-content: center;
  align-items: center;
}

.passhrase-container-video > h3 {
  margin-bottom: 10px;
  color: #343232;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.passhrase-container-video > h4 {
  margin-bottom: 20px;
  color:#343232;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.36px;
}

.passhrase-image-options {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 32px;
  margin-bottom: 36px;
  max-height: calc(100% - 96px);
}

.passhrase-description {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 850px;
}

.passhrase-image {
  order: 1;
}

.passhrase-image img {
  height: 100%;
  max-height: 480px;
  object-fit: contain;
}

.passhrase-title-options {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  order: 2;
}

.passhrase-title {
  min-width: max-content;
}

.passhrase-options {
  padding-right: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  max-height: 350px;
  overflow-y: scroll;
}

.passhrase-options::-webkit-scrollbar-track,
.passhrase-options::-webkit-scrollbar,
.passhrase-options::-webkit-scrollbar-thumb
{
	display: none;
}

.passhrase-options li {
  position: relative;
  width: auto;
  animation: fadeIn .4s linear;
}

.passhrase-options li {
  animation-fill-mode: both;
}

.passhrase-options-link {
  text-decoration: none;
}

.passhrase-option {
  display: flex;
  width: 244px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid #B72025;
  background-color: #FFF;

  color: #B72025;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.32px;

  transition: background-color .2s ease-in-out;
}

.passhrase-option:not(:disabled):hover {
  border: 1px solid #B72025;
  background-color: #FBEDED;
}
.passhrase-option:not(:disabled):active {
  border: 1px solid #9D1B1F;
  background-color: #FBEDED;
  transform: translateY(1px) scale(0.99);
}

.passhrase-option:disabled {
  border: 1px solid #A4A3A3;
  background-color: #FFF;
  color: #5D5B5B;
  cursor: not-allowed;
}

.passhrase-title > h2 {
  color: #343232;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.passhrase-description > h3 {
  margin-bottom: 8px;
  color: #343232;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.32px;
}

.passhrase-description > p {
  color: #343232;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.28px;
}

.passhrase-video-player-container {
  height: max-content;
  width: 100%;
}

.passhrase-image,
.passhrase-title,
.passhrase-description,
.passhrase-container-video {
  animation: appear .5s ease-in-out;
}

@media screen and (max-width: 800px) {
  .passhrase-container {
    justify-content: start;
    height: 100%;
    position: relative;
  }

  .passhrase-image-options {
    flex-direction: column;
    max-height: none;
  }

  .passhrase-title > h2 {
    font-size: 20px;
    line-height: 26px;
  }

  .passhrase-title-options {
    margin: 0;
    gap: 20px;
    max-height: 100%;
    order: 1;
  }

  .passhrase-options {
    max-height: max-content;
    overflow-y: hidden;
  }

  .passhrase-image {
    order: 2;
  }
}
