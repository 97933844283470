.video-player-edu,
.edu-question-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.edu-question-container {
  justify-content: flex-start;
  max-width: 1028px;
  align-items: center;
  text-align: center;
}

.edu-fasttrack-question-container {
  justify-content: space-between;
}

.edu-fasttrack-question-container h5 {
  margin: 0 0 16px 0;
  font-weight: 600;
  font-size: 16px;
  color: #343232;
}

.edu-question-container > h4 {
  display: block;
  width: 100%;
  color: #343232;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.edu-question-container > h4,
.edu-question-container > span:first-of-type {
  animation: appear 0.7s linear;
}

.edu-question-container > span:first-of-type {
  margin-bottom: 3em;
  color: #343232;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.edu-question {
  margin-bottom: 1rem;
  width: max-content;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #9d1b1f;
  cursor: pointer;
  transition: color .2s ease-in-out, transform .1s ease-in-out;
}

.edu-question:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  width: 0;
  height: 1px;
  background-color: #DE2C37;
  border-radius: 5px;
  transition: width .2s ease-in-out, left .2s ease-in-out;
}

.edu-question:hover {
  color: #DE2C37;
}

.edu-question:hover:after {
  width: 100%;
  left: 0;
}

.edu-question:active {
  transform: translateY(2px);
}

.video-name-edu {
  text-align: center;
}

.edu-question-container ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edu-question-container li {
  position: relative;
  width: auto;
  animation: fadeIn .4s linear;
}

.edu-question-container li {
  animation-fill-mode: both;
}

.edu-question-container .wrong-answer,
.edu-question-container .correct-answer {
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 700;
  line-height: 28px;
}

.edu-question-container .wrong-answer {
  color: #9d1b1f;
}

.edu-question-container .correct-answer {
  color: #385F8C;
}

.edu-question-container .wrong-answer,
.edu-question-container .correct-answer,
.edu-question-container p,
.edu-question-container div {
  animation: appear .3s linear;
}

.edu-question-container button {
  background-color: transparent;
}

.edu-question-container button:active {
  transform: scale(0.95);
}

.edu-question-container button svg {
  fill: #343232;
  width: 64px;
  height: 64px;
  transition: fill .2s ease-in-out;
}

.edu-question-container button:hover svg {
  fill: #9d1b1f;
}
