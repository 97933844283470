.sidebar {
  background: #FFFFFF;
  overflow-y: scroll;
  transition: transform .3s ease, opacity 0.3s ease, height 0.3s ease;
}

.sidebar-desktop {
  display: block;
  position: relative;
  min-width: 240px;
  max-width: 240px;
  transition: min-width .3s ease;
}

.sidebar-mobile-close {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  background-color: #F5F5F5;
  transition: opacity 0.3s ease, height 0.3s ease
}

.sidebar-mobile-open {
  position: absolute;
  width: 100vw;
  min-height: calc(100vh - 160px);
  z-index: 21;
  overflow-y: scroll;
  transition: opacity 0.3s ease, height 0.3s ease
}

.sidebar-hide {
  display: none;
}

@media screen and (max-height: 620px) {
  .sidebar-mobile-open {
    min-height: calc(100vh - 40px);
  }
}

.bar {
  padding: 24px 0;
}

.bar-category {
  padding: 0;
}

.bar-category h3 {
  background-color: #C2C1C1;
  color: #FFFFFF;
  padding: 10px 16px 6px 32px;
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.bar-item img {
  width: 20px;
  height: 20px;
}

.bar-link {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;
  gap: 20px;
  padding: 12px 16px 12px 32px;
  align-items: center;
  color: #343232;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
}

.bar-link:not(.bar-link-active):not(.bar-link-disabled):hover {
  background-color: #faf5f5;
  color: #B72025
}

.bar-link-disabled {
  cursor: default;
}

.sidebar-mobile-open .bar {
  padding: 20px 0;
}

.sidebar-mobile-open .bar-link {
  padding: 12px 20px;
}

.sidebar-mobile-open .bar-category {
  padding: 0;
}

.sidebar-mobile-open .bar-category h3 {
  padding-left: 20px;
}

.bar-link-active {
  font-weight: 600;
  color: #343232;
  background-color: #FBEDED;
}

.item-title {
  height: 44px;
  color:#5D5B5B;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
  justify-content: space-between;
}

.item-button {
  border: none;
  background-color: white;
  display: flex;
  justify-content: space-between;
  color: #5D5B5B;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.28px;
  align-items: center;
}

.item-button:hover {
  cursor: pointer;
}
