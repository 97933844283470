.welcome-page .error-message {
  position: relative;
  transform: translateX(-8px);
}
.welcome-page .error-message > span {
  position: absolute;
}

.error-img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

h2 {
  color: #333;
}

