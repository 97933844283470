@import "./normalize.css";
@import "./App.css";

button {
  cursor: pointer;
  border: none;
  outline: none;
}

svg {
  width: 2.5rem;
  height: 2.5rem;
  fill: currentColor;
}

.toast-message-info {
  top: -18px;
  background: #fff !important;
  box-shadow: 0px 1px 4px #3432321f !important;
}

.Toastify__close-button {
  align-self: center !important;
}
