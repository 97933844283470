.head {
  display: flex;
  padding: 20px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;

  background: #343232;

  transition: transform .2s ease;
}

.head-hide {
  transform: translateY(-100%);
  position: absolute;
}

.head > h3 {
  color: #FFF;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.head > p {
  display: block;
  max-width: 800px;
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.32px;
}

@media screen and (max-height: 840px) {
  .head {
    padding: 10px 30px;
    gap: 4px;
  }

  .head > p {
    font-size: 15px;
    line-height: 18px;
  }
}

@media screen and (max-height: 620px) {
  .head {
    display: none;
  }
}